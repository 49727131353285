import { Review } from "../ecommerce/Review"
import { Media } from "./Media"
import { Variant } from "./Variant"

export interface Product {
  id: number
  uuid: string
  organizationId: number
  categoryId: number
  formatId: number
  // type: TypeProductEnum
  // typePayment: TypePaymentProductEnum
  // status: StatusProductsEnum
  title: string
  description: string
  createdAt: Date
  updatedAt: Date
  visibility: string
  billet: string
  pix: string
  creditCard: string
  contactEmail?: string
  contactPhone?: string
  website?: string
  shippingType?: string
  shippingPrice?: number
  shippingDeadline?: string
  pageCreditCard?: string
  pageBillet?: string
  pagePix?: string
  pageUpsell?: string
  affiliationType: string
  seoTitle?: string
  seoUrlRewrite?: string
  seoDescription?: string

  //main variant
  priceMin?: number
  priceMax?: number

  thumbnail?: string

  variants: Partial<Variant>[]
  images: Media[]
  reviews: Review[]
}

export function generateProductSlug(text: string): string {
  return text
    .toString()
    .normalize('NFD')                   // Normalize the string to remove diacritics
    .replace(/[\u0300-\u036f]/g, '')    // Remove diacritics
    .toLowerCase()                      // Convert to lowercase
    .trim()                             // Trim leading and trailing spaces
    .replace(/[^a-z0-9\s-]/g, '')       // Remove special characters
    .replace(/\s+/g, '-')               // Replace spaces with hyphens
    .replace(/-+/g, '-');               // Replace multiple hyphens with a single hyphen
}