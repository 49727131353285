import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

import PinkThemeHeader from './components/PinkThemeHeader.vue';
import PinkThemeNav from './components/PinkThemeNav.vue';
import PinkThemeFooter from './components/PinkThemeFooter.vue';
import FloatingButton from './components/FloatingButton.vue';
import Loader from '@/components/loaders/Loader.vue';
import { onMounted } from 'vue';
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useProduct } from '@/composables/useProduct';
import { useEcommerce } from '@/composables/useEcommerce';
import { useCache } from '@/composables/useCache';


export default /*@__PURE__*/_defineComponent({
  __name: 'PinkThemeLayout',
  setup(__props) {

_useCssVars(_ctx => ({
  "5810804a": (_unref(ecommerce)?.visual?.colorPrimary ?? 'rgb(253 61 87 / var(--tw-bg-opacity))'),
  "20b5992a": (_unref(ecommerce)?.visual?.colorMenu ?? 'rgb(31 41 55 / var(--tw-bg-opacity))'),
  "4b1b9711": (_unref(ecommerce)?.visual?.footerColor ?? 'rgb(31 41 55 / var(--tw-bg-opacity))')
}))

const { needUpdate } = useCache()
const { loadEcommerce, isEcommerceLoading, ecommerce } = useEcommerce()
const { loadCategories, isCategoriesLoading } = useCategory()
const { loadCollections, isCollectionsLoading } = useCollection()
const { loadProducts, isProductsLoading } = useProduct()

onMounted(async () => {
  const lastUpdate = await needUpdate();
  const localLastUpdate = localStorage.getItem('lastUpdate');

  const isUpdated = localLastUpdate !== String(lastUpdate);

  if (isUpdated) {
    localStorage.setItem('lastUpdate', String(lastUpdate));
  }

  const shouldReload = isUpdated; // `true` para atualizar, `false` se já estiver sincronizado
  loadEcommerce(shouldReload);
  loadCategories(shouldReload);
  loadCollections(shouldReload);
  loadProducts(shouldReload);
});

return (_ctx: any,_cache: any) => {
  return (!_unref(isEcommerceLoading) && !_unref(isCategoriesLoading) && !_unref(isCollectionsLoading) && !_unref(isProductsLoading))
    ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
        _createVNode(PinkThemeHeader),
        _createVNode(PinkThemeNav),
        _renderSlot(_ctx.$slots, "default"),
        _createVNode(FloatingButton),
        _createVNode(PinkThemeFooter)
      ]))
    : (_openBlock(), _createBlock(Loader, { key: 1 }))
}
}

})