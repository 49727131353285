<template>
  <!-- navbar -->
  <nav class="bg-gray-800">
    <div class="container flex">
      <div
        class="px-4 sm:px-6 sm:py-4 bg-primary flex items-center cursor-pointer relative group"
      >
        <span class="text-white">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
            <path d="M3 7H21" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M3 12H21" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M3 17H21" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </span>
        <span class="capitalize ml-2 text-white hidden">Todas categorias</span>

        <!-- dropdown -->
        <div
          class="absolute left-0 top-full bg-white shadow-md py-3 divide-y divide-gray-300 divide-dashed opacity-0 group-hover:opacity-100 transition duration-300 invisible group-hover:visible w-64"
        >
          <RouterLink
            v-for="category in categories"
            :to="{ name: 'shop', query: { categoryId: category.id } }"
            class="flex items-center px-6 py-3 hover:bg-gray-100 transition"
          >
            <!-- <img
              src="assets/images/icons/sofa.svg"
              alt="sofa"
              class="w-5 h-5 object-contain"
            /> -->
            <span class="ml-6 text-gray-600 text-sm">{{ category.title }}</span>
          </RouterLink>
          <RouterLink
            v-for="collection in collections"
            :to="{ name: 'shop', query: { collectionId: collection.id } }"
            class="flex items-center px-6 py-3 hover:bg-gray-100 transition"
          >
            <!-- <img
              src="assets/images/icons/sofa.svg"
              alt="sofa"
              class="w-5 h-5 object-contain"
            /> -->
            <span class="ml-6 text-gray-600 text-sm">{{ collection.title }}</span>
          </RouterLink>
        </div>
      </div>

      <div class="flex items-center justify-between flex-grow pl-8 md:pl-12 py-5">
        <div class="flex items-center space-x-6 capitalize">
          <RouterLink 
          :to="{ name: 'home' }" 
          class="text-menu hover:text-menu-hover transition"
          :active-class="'text-menu-hover'"
            >Home</RouterLink
          >
          <RouterLink
            :to="{ name: 'shop' }"
            class="text-menu hover:text-menu-hover transition"
            :active-class="'text-menu-hover'"
            >Produtos</RouterLink
          >
          <a v-for="menu in ecommerce?.menus" :href="menu.href" :key="menu.id">{{ menu.title }}</a>
        </div>
      </div>
    </div>
  </nav>
  <!-- ./navbar -->
</template>

<script setup lang="ts">
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useEcommerce } from '@/composables/useEcommerce';

const { categories } = useCategory()
const { collections } = useCollection()
const { ecommerce } = useEcommerce()
</script>

<style lang="scss" scoped>
$menu-color: v-bind("ecommerce?.visual?.colorMenuText ?? 'rgb(253 61 87 / var(--tw-bg-opacity))'");
$menu-color-hover: v-bind("ecommerce?.visual?.colorMenuHover ?? 'rgb(253 61 87 / var(--tw-bg-opacity))'");

.text-menu {
  color: $menu-color
}
.text-menu-hover, .hover\:text-menu-hover:hover {
  color: $menu-color-hover !important
}
</style>