import router from "@/router";
import axios from "axios";

interface ApiError {
  uniqueCode: string
  message: string
}

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.data?.errors?.length && error.response?.data.errors.find((e: ApiError) => e.uniqueCode === "ECOMMERCE:NOT_FOUND")) {
      router.push({ name: 'ecommerce.not-found' })
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
