<template>
  <!-- footer -->
  <footer class="bg-white pt-16 pb-12 border-t border-gray-100">
    <div class="container grid grid-cols-1">
      <div class="col-span-1 space-y-4">
        <img :src="ecommerce?.visual?.urlLogo" alt="logo" class="w-30" />
        <div class="mr-2">
          <!-- <p class="text-gray-500 text-sm">
            (00) 0 0000-0000
          </p> -->
          <p class="text-gray-500 text-sm">
            {{ ecommerce.email }}
          </p>
          <p class="text-gray-500 text-sm">
            {{ ecommerce.address?.street }}, {{ ecommerce.address?.district }}, Número {{ ecommerce.address?.number }}, {{ ecommerce.address?.complement }}
          </p>
        </div>
        <div class="flex space-x-5">
          <a href="#" class="text-gray-400 hover:text-gray-500"
            ><i class="fa-brands fa-facebook-square"></i
          ></a>
          <a href="#" class="text-gray-400 hover:text-gray-500"
            ><i class="fa-brands fa-instagram-square"></i
          ></a>
          <a href="#" class="text-gray-400 hover:text-gray-500"
            ><i class="fa-brands fa-twitter-square"></i
          ></a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <i class="fa-brands fa-github-square"></i>
          </a>
        </div>
      </div>

      <!-- <div class="col-span-2 grid grid-cols-2 gap-4">
        <div class="grid grid-cols-2 gap-4 md:gap-8">
          <div>
            <h3
              class="text-sm font-semibold text-gray-400 uppercase tracking-wider"
            >
              Solutions
            </h3>
            <div class="mt-4 space-y-4">
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >Marketing</a
              >
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >Analitycs</a
              >
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >Commerce</a
              >
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >Insights</a
              >
            </div>
          </div>

          <div>
            <h3
              class="text-sm font-semibold text-gray-400 uppercase tracking-wider"
            >
              Support
            </h3>
            <div class="mt-4 space-y-4">
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >Pricing</a
              >
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >Guides</a
              >
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >API Status</a
              >
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-8">
          <div>
            <h3
              class="text-sm font-semibold text-gray-400 uppercase tracking-wider"
            >
              Solutions
            </h3>
            <div class="mt-4 space-y-4">
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >Marketing</a
              >
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >Analitycs</a
              >
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >Commerce</a
              >
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >Insights</a
              >
            </div>
          </div>

          <div>
            <h3
              class="text-sm font-semibold text-gray-400 uppercase tracking-wider"
            >
              Support
            </h3>
            <div class="mt-4 space-y-4">
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >Pricing</a
              >
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >Guides</a
              >
              <a
                href="#"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >API Status</a
              >
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </footer>
  <!-- ./footer -->

  <!-- copyright -->
  <div class="bg-gray-800 py-4 bottom-footer">
    <div class="container flex items-center justify-between">
      <p class="text-white">Copyright &copy; {{ ecommerce.title }} - {{ formatDocument(ecommerce.document) }} - {{ new Date().getFullYear() }}. Todos os direitos reservados.</p>
      <div class="flex gap-2 items-center flex-wrap">
        <img src="assets/images/payment-methods/pix.png" alt="methods" class="h-5" />
        <img src="assets/images/payment-methods/boleto.jpeg" alt="methods" class="h-5" />
        <img src="assets/images/payment-methods/bandeiras/elo.png" alt="methods" class="h-5" />
        <img src="assets/images/payment-methods/bandeiras/hipercard.svg" alt="methods" class="h-5" />
        <img src="assets/images/payment-methods/bandeiras/master.svg" alt="methods" class="h-5" />
        <img src="assets/images/payment-methods/bandeiras/visa.svg" alt="methods" class="h-5" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEcommerce } from '@/composables/useEcommerce';
import { useFormat } from '@/composables/useFormat';

const { formatDocument } = useFormat()
const { ecommerce } = useEcommerce()
</script>