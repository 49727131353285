<template>
  <PinkThemeLayout>
    <!-- banner -->
    <div
      class="bg-cover bg-no-repeat bg-center py-36"
      :style="{ backgroundImage: `url('${topBanner.url}')` }"
      v-if="topBanner"
    >
      <div class="container">
        <h1 class="text-6xl text-gray-800 font-medium mb-4 capitalize">
          {{ topBanner.name }}
        </h1>
        <p>{{ topBanner.description }}</p>
        <div class="mt-12">
          <a
            :href="topBanner.redirectUrl"
            class="bg-primary border border-primary text-white px-8 py-3 font-medium rounded-md hover:bg-transparent hover:text-primary"
            >{{ topBanner.buttonText }}</a
          >
        </div>
      </div>
    </div>
    <!-- ./banner -->
  
    <!-- features -->
    <!-- <div class="container py-16">
      <div
        class="w-10/12 grid grid-cols-1 md:grid-cols-3 gap-6 mx-auto justify-center"
      >
        <div
          class="border border-primary rounded-sm px-3 py-6 flex justify-center items-center gap-5"
        >
          <img
            src="assets/images/icons/delivery-van.svg"
            alt="Delivery"
            class="w-12 h-12 object-contain"
          />
          <div>
            <h4 class="font-medium capitalize text-lg">Frete Grátis</h4>
            <p class="text-gray-500 text-sm">Pedido acima de R$ 200</p>
          </div>
        </div>
        <div
          class="border border-primary rounded-sm px-3 py-6 flex justify-center items-center gap-5"
        >
          <img
            src="assets/images/icons/money-back.svg"
            alt="Delivery"
            class="w-12 h-12 object-contain"
          />
          <div>
            <h4 class="font-medium capitalize text-lg">Reembolso</h4>
            <p class="text-gray-500 text-sm">30 dias</p>
          </div>
        </div>
        <div
          class="border border-primary rounded-sm px-3 py-6 flex justify-center items-center gap-5"
        >
          <img
            src="assets/images/icons/service-hours.svg"
            alt="Delivery"
            class="w-12 h-12 object-contain"
          />
          <div>
            <h4 class="font-medium capitalize text-lg">24h/7d Suporte</h4>
            <p class="text-gray-500 text-sm">Suporte ao cliente</p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ./features -->
  
    <!-- categories -->
    <!-- <div class="container py-16">
      <h2 class="text-2xl font-medium text-gray-800 uppercase mb-6">
        categorias
      </h2>
      <div class="grid grid-cols-2 sm:grid-cols-3 gap-3">
        <RouterLink :to="{ name: 'shop', query: { categoryId: category.id } }" class="relative rounded-sm overflow-hidden group" v-for="(category, index) in categories" :key="index">
          <img
            src="assets/images/category/category-1.jpg"
            alt="category 1"
            class="w-full"
            @error="($event.target as HTMLImageElement).src = '/assets/images/no-image.png'"
          />
          <a
            href="#"
            class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition"
            >{{ category.title }}</a
          >
        </RouterLink>
      </div>
    </div> -->
    <!-- ./categories -->

    <!-- categories -->
    <!-- <div class="container py-16">
      <h2 class="text-2xl font-medium text-gray-800 uppercase mb-6">
        coleções
      </h2>
      <div class="grid grid-cols-2 sm:grid-cols-3 gap-3">
        <RouterLink :to="{ name: 'shop', query: { collectionId: collection.id } }" class="relative rounded-sm overflow-hidden group" v-for="collection in collections">
          <img
            src="assets/images/category/category-1.jpg"
            alt="category 1"
            class="w-full"
            @error="($event.target as HTMLImageElement).src = '/assets/images/no-image.png'"
          />
          <a
            href="#"
            class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition"
            >{{ collection.title }}</a
          >
        </RouterLink>
      </div>
    </div> -->
    <!-- ./categories -->
  
    <!-- new arrival -->
    <template v-for="category in categories" :key="category.id">
      <div class="container pb-16 pt-16" v-if="category.products?.length">
        <h2 class="text-2xl font-medium text-gray-800 uppercase mb-6">
          {{ category.title }}
        </h2>
        <div class="grid grid-cols-1 md:grid-cols-4 gap-6">
          <RouterLink :to="{ name: 'product', params: { slug: generateProductSlug(product.title) } }" class="bg-white shadow rounded overflow-hidden group" v-for="product in category.products">
            <div class="relative">
              <img
                :src="product.images?.length ? product.images[0].source : '/assets/images/no-image.png'"
                alt="product 1"
                class="w-full object-contain"
                style="height: 20rem"
                @error="($event.target as HTMLImageElement).src = '/assets/images/no-image.png'"
              />
              <div
                class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition"
              >
                <!-- <a
                  href="#"
                  class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                  title="view product"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </a>
                <a
                  href="#"
                  class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                  title="add to wishlist"
                >
                  <i class="fa-solid fa-heart"></i>
                </a> -->
              </div>
            </div>
            <div class="pt-4 pb-3 px-4">
              <a href="#">
                <h4
                  class="uppercase font-medium text-xl mb-2 text-gray-800 hover:text-primary transition"
                >
                  {{ product.title }}
                </h4>
              </a>
              <div class="flex items-baseline mb-1 space-x-2">
                <template v-if="product.variants[0].promotionalPrice">
                  <p class="text-xl text-primary font-semibold">{{ product.variants[0].promotionalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                  <p class="text-sm text-gray-400 line-through">{{ product.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                </template>
                <template v-else>
                  <p class="text-xl text-primary font-semibold">{{ product.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                </template>
              </div>
              <!-- <div class="flex items-center">
                <div class="flex gap-1 text-sm text-yellow-400">
                  <span><i class="fa-solid fa-star"></i></span>
                  <span><i class="fa-solid fa-star"></i></span>
                  <span><i class="fa-solid fa-star"></i></span>
                  <span><i class="fa-solid fa-star"></i></span>
                  <span><i class="fa-solid fa-star"></i></span>
                </div>
                <div class="text-xs text-gray-500 ml-3">(150)</div>
              </div> -->
            </div>
            <a
              href="#"
              class="block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition"
              >Adicionar</a
            >
          </RouterLink>
        </div>
      </div>
    </template>
    <!-- ./new arrival -->
  
    <!-- ads -->
    <div class="container pb-16 flex flex-col gap-8">
      <a :href="banner.redirectUrl" v-for="banner in bottomBanners">
        <img :src="banner.url" alt="ads" class="w-full" />
      </a>
    </div>
    <!-- ./ads -->
  
    <!-- product -->
    <!-- <div class="container pb-16">
      <h2 class="text-2xl font-medium text-gray-800 uppercase mb-6">
        Recomendados
      </h2>
      <div class="grid grid-cols-2 md:grid-cols-4 gap-6">
        <div class="bg-white shadow rounded overflow-hidden group">
          <div class="relative">
            <img
              src="assets/images/products/product1.jpg"
              alt="product 1"
              class="w-full"
              @error="($event.target as HTMLImageElement).src = '/assets/images/no-image.png'"
            />
            <div
              class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition"
            >
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="view product"
              >
                <i class="fa-solid fa-magnifying-glass"></i>
              </a>
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="add to wishlist"
              >
                <i class="fa-solid fa-heart"></i>
              </a>
            </div>
          </div>
          <div class="pt-4 pb-3 px-4">
            <a href="#">
              <h4
                class="uppercase font-medium text-xl mb-2 text-gray-800 hover:text-primary transition"
              >
                Guyer Chair
              </h4>
            </a>
            <div class="flex items-baseline mb-1 space-x-2">
              <p class="text-xl text-primary font-semibold">$45.00</p>
              <p class="text-sm text-gray-400 line-through">$55.90</p>
            </div>
            <div class="flex items-center">
              <div class="flex gap-1 text-sm text-yellow-400">
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
              </div>
              <div class="text-xs text-gray-500 ml-3">(150)</div>
            </div>
          </div>
          <a
            href="#"
            class="block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition"
            >Adicionar</a
          >
        </div>
        <div class="bg-white shadow rounded overflow-hidden group">
          <div class="relative">
            <img
              src="assets/images/products/product4.jpg"
              alt="product 1"
              class="w-full"
            />
            <div
              class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition"
            >
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="view product"
              >
                <i class="fa-solid fa-magnifying-glass"></i>
              </a>
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="add to wishlist"
              >
                <i class="fa-solid fa-heart"></i>
              </a>
            </div>
          </div>
          <div class="pt-4 pb-3 px-4">
            <a href="#">
              <h4
                class="uppercase font-medium text-xl mb-2 text-gray-800 hover:text-primary transition"
              >
                Bed King Size
              </h4>
            </a>
            <div class="flex items-baseline mb-1 space-x-2">
              <p class="text-xl text-primary font-semibold">$45.00</p>
              <p class="text-sm text-gray-400 line-through">$55.90</p>
            </div>
            <div class="flex items-center">
              <div class="flex gap-1 text-sm text-yellow-400">
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
              </div>
              <div class="text-xs text-gray-500 ml-3">(150)</div>
            </div>
          </div>
          <a
            href="#"
            class="block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition"
            >Adicionar</a
          >
        </div>
        <div class="bg-white shadow rounded overflow-hidden group">
          <div class="relative">
            <img
              src="assets/images/products/product2.jpg"
              alt="product 1"
              class="w-full"
            />
            <div
              class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition"
            >
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="view product"
              >
                <i class="fa-solid fa-magnifying-glass"></i>
              </a>
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="add to wishlist"
              >
                <i class="fa-solid fa-heart"></i>
              </a>
            </div>
          </div>
          <div class="pt-4 pb-3 px-4">
            <a href="#">
              <h4
                class="uppercase font-medium text-xl mb-2 text-gray-800 hover:text-primary transition"
              >
                Couple Sofa
              </h4>
            </a>
            <div class="flex items-baseline mb-1 space-x-2">
              <p class="text-xl text-primary font-semibold">$45.00</p>
              <p class="text-sm text-gray-400 line-through">$55.90</p>
            </div>
            <div class="flex items-center">
              <div class="flex gap-1 text-sm text-yellow-400">
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
              </div>
              <div class="text-xs text-gray-500 ml-3">(150)</div>
            </div>
          </div>
          <a
            href="#"
            class="block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition"
            >Adicionar</a
          >
        </div>
        <div class="bg-white shadow rounded overflow-hidden group">
          <div class="relative">
            <img
              src="assets/images/products/product3.jpg"
              alt="product 1"
              class="w-full"
            />
            <div
              class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition"
            >
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="view product"
              >
                <i class="fa-solid fa-magnifying-glass"></i>
              </a>
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="add to wishlist"
              >
                <i class="fa-solid fa-heart"></i>
              </a>
            </div>
          </div>
          <div class="pt-4 pb-3 px-4">
            <a href="#">
              <h4
                class="uppercase font-medium text-xl mb-2 text-gray-800 hover:text-primary transition"
              >
                Mattrass X
              </h4>
            </a>
            <div class="flex items-baseline mb-1 space-x-2">
              <p class="text-xl text-primary font-semibold">$45.00</p>
              <p class="text-sm text-gray-400 line-through">$55.90</p>
            </div>
            <div class="flex items-center">
              <div class="flex gap-1 text-sm text-yellow-400">
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
              </div>
              <div class="text-xs text-gray-500 ml-3">(150)</div>
            </div>
          </div>
          <a
            href="#"
            class="block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition"
            >Adicionar</a
          >
        </div>
        <div class="bg-white shadow rounded overflow-hidden group">
          <div class="relative">
            <img
              src="assets/images/products/product1.jpg"
              alt="product 1"
              class="w-full"
            />
            <div
              class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition"
            >
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="view product"
              >
                <i class="fa-solid fa-magnifying-glass"></i>
              </a>
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="add to wishlist"
              >
                <i class="fa-solid fa-heart"></i>
              </a>
            </div>
          </div>
          <div class="pt-4 pb-3 px-4">
            <a href="#">
              <h4
                class="uppercase font-medium text-xl mb-2 text-gray-800 hover:text-primary transition"
              >
                Guyer Chair
              </h4>
            </a>
            <div class="flex items-baseline mb-1 space-x-2">
              <p class="text-xl text-primary font-semibold">$45.00</p>
              <p class="text-sm text-gray-400 line-through">$55.90</p>
            </div>
            <div class="flex items-center">
              <div class="flex gap-1 text-sm text-yellow-400">
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
              </div>
              <div class="text-xs text-gray-500 ml-3">(150)</div>
            </div>
          </div>
          <a
            href="#"
            class="block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition"
            >Adicionar</a
          >
        </div>
        <div class="bg-white shadow rounded overflow-hidden group">
          <div class="relative">
            <img
              src="assets/images/products/product4.jpg"
              alt="product 1"
              class="w-full"
            />
            <div
              class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition"
            >
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="view product"
              >
                <i class="fa-solid fa-magnifying-glass"></i>
              </a>
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="add to wishlist"
              >
                <i class="fa-solid fa-heart"></i>
              </a>
            </div>
          </div>
          <div class="pt-4 pb-3 px-4">
            <a href="#">
              <h4
                class="uppercase font-medium text-xl mb-2 text-gray-800 hover:text-primary transition"
              >
                Bed King Size
              </h4>
            </a>
            <div class="flex items-baseline mb-1 space-x-2">
              <p class="text-xl text-primary font-semibold">$45.00</p>
              <p class="text-sm text-gray-400 line-through">$55.90</p>
            </div>
            <div class="flex items-center">
              <div class="flex gap-1 text-sm text-yellow-400">
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
              </div>
              <div class="text-xs text-gray-500 ml-3">(150)</div>
            </div>
          </div>
          <a
            href="#"
            class="block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition"
            >Adicionar</a
          >
        </div>
        <div class="bg-white shadow rounded overflow-hidden group">
          <div class="relative">
            <img
              src="assets/images/products/product2.jpg"
              alt="product 1"
              class="w-full"
            />
            <div
              class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition"
            >
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="view product"
              >
                <i class="fa-solid fa-magnifying-glass"></i>
              </a>
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="add to wishlist"
              >
                <i class="fa-solid fa-heart"></i>
              </a>
            </div>
          </div>
          <div class="pt-4 pb-3 px-4">
            <a href="#">
              <h4
                class="uppercase font-medium text-xl mb-2 text-gray-800 hover:text-primary transition"
              >
                Couple Sofa
              </h4>
            </a>
            <div class="flex items-baseline mb-1 space-x-2">
              <p class="text-xl text-primary font-semibold">$45.00</p>
              <p class="text-sm text-gray-400 line-through">$55.90</p>
            </div>
            <div class="flex items-center">
              <div class="flex gap-1 text-sm text-yellow-400">
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
              </div>
              <div class="text-xs text-gray-500 ml-3">(150)</div>
            </div>
          </div>
          <a
            href="#"
            class="block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition"
            >Adicionar</a
          >
        </div>
        <div class="bg-white shadow rounded overflow-hidden group">
          <div class="relative">
            <img
              src="assets/images/products/product3.jpg"
              alt="product 1"
              class="w-full"
            />
            <div
              class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition"
            >
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="view product"
              >
                <i class="fa-solid fa-magnifying-glass"></i>
              </a>
              <a
                href="#"
                class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                title="add to wishlist"
              >
                <i class="fa-solid fa-heart"></i>
              </a>
            </div>
          </div>
          <div class="pt-4 pb-3 px-4">
            <a href="#">
              <h4
                class="uppercase font-medium text-xl mb-2 text-gray-800 hover:text-primary transition"
              >
                Mattrass X
              </h4>
            </a>
            <div class="flex items-baseline mb-1 space-x-2">
              <p class="text-xl text-primary font-semibold">$45.00</p>
              <p class="text-sm text-gray-400 line-through">$55.90</p>
            </div>
            <div class="flex items-center">
              <div class="flex gap-1 text-sm text-yellow-400">
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
                <span><i class="fa-solid fa-star"></i></span>
              </div>
              <div class="text-xs text-gray-500 ml-3">(150)</div>
            </div>
          </div>
          <a
            href="#"
            class="block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition"
            >Adicionar</a
          >
        </div>
      </div>
    </div> -->
    <!-- ./product -->
  </PinkThemeLayout>
</template>

<script lang="ts" setup>
import PinkThemeLayout from './layouts/PinkThemeLayout.vue';
import { useCollection } from '@/composables/useCollection';
import { useCategory } from '@/composables/useCategory';
import { generateProductSlug } from '@/interfaces/products/Product';
import { useEcommerce } from '@/composables/useEcommerce';

const { ecommerce, topBanner, bottomBanners } = useEcommerce()
const { categories } = useCategory()
const { collections } = useCollection()
</script>
