import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["id", "name", "checked", "disabled"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id", "name", "checked", "disabled"]
const _hoisted_5 = ["for"]
const _hoisted_6 = ["id", "name", "checked", "disabled"]
const _hoisted_7 = ["for"]
const _hoisted_8 = ["id", "name", "checked", "disabled"]
const _hoisted_9 = ["for"]
const _hoisted_10 = ["id", "name", "checked", "disabled"]
const _hoisted_11 = ["for"]
const _hoisted_12 = ["id", "name", "checked", "disabled"]
const _hoisted_13 = ["for"]
const _hoisted_14 = ["id", "name", "checked", "disabled"]
const _hoisted_15 = ["for"]
const _hoisted_16 = ["id", "name", "checked", "disabled"]
const _hoisted_17 = ["for"]
const _hoisted_18 = ["id", "name", "checked", "disabled"]
const _hoisted_19 = ["for"]
const _hoisted_20 = ["id", "name", "checked", "disabled"]
const _hoisted_21 = ["for"]

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RatingInput',
  props: {
    value: {},
    name: {},
    id: {},
    disabled: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emits = __emit;
const currentRating = ref(props.value);

const setRating = (value: number) => {
  currentRating.value = value;
  emits("update:modelValue", value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("fieldset", {
    class: "rate",
    disabled: _ctx.disabled
  }, [
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.id + '10',
      name: _ctx.name,
      value: "10",
      checked: currentRating.value === 5,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (setRating(5))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.id + '10',
      title: "5 estrelas"
    }, null, 8, _hoisted_3),
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.id + '9',
      name: _ctx.name,
      value: "9",
      checked: currentRating.value === 4.5,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (setRating(4.5))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_4),
    _createElementVNode("label", {
      class: "half",
      for: _ctx.id + '9',
      title: "4 1/2 estrelas"
    }, null, 8, _hoisted_5),
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.id + '8',
      name: _ctx.name,
      value: "8",
      checked: currentRating.value === 4,
      onChange: _cache[2] || (_cache[2] = ($event: any) => (setRating(4))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_6),
    _createElementVNode("label", {
      for: _ctx.id + '8',
      title: "4 estrelas"
    }, null, 8, _hoisted_7),
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.id + '7',
      name: _ctx.name,
      value: "7",
      checked: currentRating.value === 3.5,
      onChange: _cache[3] || (_cache[3] = ($event: any) => (setRating(3.5))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_8),
    _createElementVNode("label", {
      class: "half",
      for: _ctx.id + '7',
      title: "3 1/2 estrelas"
    }, null, 8, _hoisted_9),
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.id + '6',
      name: _ctx.name,
      value: "6",
      checked: currentRating.value === 3,
      onChange: _cache[4] || (_cache[4] = ($event: any) => (setRating(3))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_10),
    _createElementVNode("label", {
      for: _ctx.id + '6',
      title: "3 estrelas"
    }, null, 8, _hoisted_11),
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.id + '5',
      name: _ctx.name,
      value: "5",
      checked: currentRating.value === 2.5,
      onChange: _cache[5] || (_cache[5] = ($event: any) => (setRating(2.5))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_12),
    _createElementVNode("label", {
      class: "half",
      for: _ctx.id + '5',
      title: "2 1/2 estrelas"
    }, null, 8, _hoisted_13),
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.id + '4',
      name: _ctx.name,
      value: "4",
      checked: currentRating.value === 2,
      onChange: _cache[6] || (_cache[6] = ($event: any) => (setRating(2))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_14),
    _createElementVNode("label", {
      for: _ctx.id + '4',
      title: "2 estrelas"
    }, null, 8, _hoisted_15),
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.id + '3',
      name: _ctx.name,
      value: "3",
      checked: currentRating.value === 1.5,
      onChange: _cache[7] || (_cache[7] = ($event: any) => (setRating(1.5))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_16),
    _createElementVNode("label", {
      class: "half",
      for: _ctx.id + '3',
      title: "1 1/2 estrelas"
    }, null, 8, _hoisted_17),
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.id + '2',
      name: _ctx.name,
      value: "2",
      checked: currentRating.value === 1,
      onChange: _cache[8] || (_cache[8] = ($event: any) => (setRating(1))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_18),
    _createElementVNode("label", {
      for: _ctx.id + '2',
      title: "1 estrela"
    }, null, 8, _hoisted_19),
    _createElementVNode("input", {
      type: "radio",
      id: _ctx.id + '1',
      name: _ctx.name,
      value: "1",
      checked: currentRating.value === 0.5,
      onChange: _cache[9] || (_cache[9] = ($event: any) => (setRating(0.5))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_20),
    _createElementVNode("label", {
      class: "half",
      for: _ctx.id + '1',
      title: "1/2 estrela"
    }, null, 8, _hoisted_21)
  ], 8, _hoisted_1))
}
}

})