import axiosClient from "@/config/axios";
import { Category } from "@/interfaces/Category";
import { Product } from "@/interfaces/products/Product";
import { ref } from "vue";

const categories = ref<Category[]>(JSON.parse(localStorage.getItem('categories') ?? '[]'))
const isCategoriesLoading = ref(false)

export function useCategory() {
  function loadCategories(refresh = false) {
    if (!categories.value.length || refresh) {
      isCategoriesLoading.value = true 

      axiosClient.get('/categories')
        .then((res) => {
          categories.value = res.data
          localStorage.setItem('categories', JSON.stringify(res.data))
        })
        .finally(() => isCategoriesLoading.value = false)
    }
  }

  return {
    categories,
    loadCategories,
    isCategoriesLoading
  }
}