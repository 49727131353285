<template>
  <PinkThemeLayout>
    <!-- breadcrumb -->
    <!-- <div class="container py-4 flex items-center gap-3">
      <a href="index.html" class="text-primary text-base">
        <i class="fa-solid fa-house"></i>
      </a>
      <span class="text-sm text-gray-400">
        <i class="fa-solid fa-chevron-right"></i>
      </span>
      <p class="text-gray-600 font-medium">Shop</p>
    </div> -->
    <!-- ./breadcrumb -->
  
    <!-- shop wrapper -->
    <div
      class="container grid md:grid-cols-4 grid-cols-2 gap-6 pt-4 pb-16 items-start"
    >
      <!-- sidebar -->
      <!-- drawer init and toggle -->
      <div class="text-center md:hidden">
        <button
          class="text-white hover:bg-blue-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2 border-2 focus:outline-none dark:focus:ring-blue-800 block md:hidden"
          type="button"
          data-drawer-target="drawer-example"
          data-drawer-show="drawer-example"
          aria-controls="drawer-example"
          @click="isMobileSidebarOpen = true"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20.5999 4.1001V6.3001C20.5999 7.1001 20.0999 8.1001 19.5999 8.6001L15.2999 12.4001C14.6999 12.9001 14.2999 13.9001 14.2999 14.7001V19.0001C14.2999 19.6001 13.8999 20.4001 13.3999 20.7001L11.9999 21.6001C10.6999 22.4001 8.89988 21.5001 8.89988 19.9001V14.6001C8.89988 13.9001 8.49989 13.0001 8.09989 12.5001L7.62989 12.0101C7.31989 11.6801 7.25989 11.1801 7.50989 10.7901L12.6299 2.5701C12.8099 2.2801 13.1299 2.1001 13.4799 2.1001H18.5999C19.6999 2.1001 20.5999 3.0001 20.5999 4.1001Z" fill="#171717"/>
            <path d="M10.3499 3.6301L6.7999 9.3201C6.4599 9.8701 5.6799 9.9501 5.2299 9.4801L4.2999 8.5001C3.7999 8.0001 3.3999 7.1001 3.3999 6.5001V4.2001C3.3999 3.0001 4.2999 2.1001 5.3999 2.1001H9.4999C10.2799 2.1001 10.7599 2.9601 10.3499 3.6301Z" fill="#171717"/>
          </svg>
        </button>
      </div>
  
      <!-- drawer component -->
      <div
        id="drawer-example"
        class="fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-white w-80 bg-gray-800"
        :class="isMobileSidebarOpen ? '' : '-translate-x-full'"
        tabindex="-1"
        aria-labelledby="drawer-label"
      >
        <!-- <h5
          id="drawer-label"
          class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
        >
          <svg
            class="w-5 h-5 mr-2"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            ></path></svg
          >Info
        </h5> -->
        <button
          type="button"
          data-drawer-hide="drawer-example"
          aria-controls="drawer-example"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          @click="isMobileSidebarOpen = false"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Fechar menu</span>
        </button>
        <div class="divide-y divide-gray-200 space-y-5">
          <div v-if="!$route.query.collectionId && !$route.query.categoryId">
            <h3 class="text-xl text-gray-800 mb-3 uppercase font-medium">
              Categorias
            </h3>
            <div class="space-y-2">
              <div class="flex items-center" v-for="category in categories">
                <input
                  type="radio"
                  name="cat-1"
                  :id="`cat-${category.id}`"
                  class="text-primary focus:ring-0 rounded-full cursor-pointer"
                  v-model="filter.categoryId"
                  :value="category.id"
                />
                <label :for="`cat-${category.id}`" class="text-gray-600 ml-3 cursor-pointer"
                  >{{ category.title }}</label
                >
                <div class="ml-auto text-gray-600 text-sm">({{ category.products?.length ?? 0 }})</div>
              </div>
            </div>
          </div>
  
          <div class="pt-4" v-if="!$route.query.collectionId && !$route.query.categoryId">
            <h3 class="text-xl text-gray-800 mb-3 uppercase font-medium">
              Coleções
            </h3>
            <div class="space-y-2">
              <div class="flex items-center" v-for="collection in collections">
                <input
                  type="radio"
                  name="brand-1"
                  :id="`collection-${collection.id}`"
                  class="text-primary focus:ring-0 rounded-full cursor-pointer"
                  :value="collection.id"
                  v-model="filter.collectionId"
                />
                <label :for="`collection-${collection.id}`" class="text-gray-600 ml-3 cursor-pointer"
                  >{{ collection.title }}</label
                >
                <!-- <div class="ml-auto text-gray-600 text-sm">(15)</div> -->
              </div>
            </div>
          </div>
  
          <div class="pt-4">
            <h3 class="text-xl text-gray-800 mb-3 uppercase font-medium">
              Preço
            </h3>
            <div class="mt-4 flex items-center">
              <input
                type="number"
                name="min"
                id="min"
                class="w-full border-gray-300 focus:border-primary rounded focus:ring-0 px-3 py-1 text-gray-600 shadow-sm"
                placeholder="min"
                v-model="filter.minPrice"
              />
              <span class="mx-3 text-gray-500">-</span>
              <input
                type="number"
                name="max"
                id="max"
                class="w-full border-gray-300 focus:border-primary rounded focus:ring-0 px-3 py-1 text-gray-600 shadow-sm"
                placeholder="max"
                v-model="filter.maxPrice"
              />
            </div>
          </div>
  
          <!-- <div class="pt-4">
            <h3 class="text-xl text-gray-800 mb-3 uppercase font-medium">tamanho</h3>
            <div class="flex items-center gap-2">
              <div class="size-selector">
                <input type="radio" name="size" id="size-xs" class="hidden" />
                <label
                  for="size-xs"
                  class="text-xs border border-gray-200 rounded-sm h-6 w-6 flex items-center justify-center cursor-pointer shadow-sm text-gray-600"
                  >XS</label
                >
              </div>
              <div class="size-selector">
                <input type="radio" name="size" id="size-sm" class="hidden" />
                <label
                  for="size-sm"
                  class="text-xs border border-gray-200 rounded-sm h-6 w-6 flex items-center justify-center cursor-pointer shadow-sm text-gray-600"
                  >S</label
                >
              </div>
              <div class="size-selector">
                <input type="radio" name="size" id="size-m" class="hidden" />
                <label
                  for="size-m"
                  class="text-xs border border-gray-200 rounded-sm h-6 w-6 flex items-center justify-center cursor-pointer shadow-sm text-gray-600"
                  >M</label
                >
              </div>
              <div class="size-selector">
                <input type="radio" name="size" id="size-l" class="hidden" />
                <label
                  for="size-l"
                  class="text-xs border border-gray-200 rounded-sm h-6 w-6 flex items-center justify-center cursor-pointer shadow-sm text-gray-600"
                  >L</label
                >
              </div>
              <div class="size-selector">
                <input type="radio" name="size" id="size-xl" class="hidden" />
                <label
                  for="size-xl"
                  class="text-xs border border-gray-200 rounded-sm h-6 w-6 flex items-center justify-center cursor-pointer shadow-sm text-gray-600"
                  >XL</label
                >
              </div>
            </div>
          </div>
  
          <div class="pt-4">
            <h3 class="text-xl text-gray-800 mb-3 uppercase font-medium">
              Cor
            </h3>
            <div class="flex items-center gap-2">
              <div class="color-selector">
                <input type="radio" name="color" id="red" class="hidden" />
                <label
                  for="red"
                  class="border border-gray-200 rounded-sm h-6 w-6 cursor-pointer shadow-sm block"
                  style="background-color: #fc3d57"
                ></label>
              </div>
              <div class="color-selector">
                <input type="radio" name="color" id="black" class="hidden" />
                <label
                  for="black"
                  class="border border-gray-200 rounded-sm h-6 w-6 cursor-pointer shadow-sm block"
                  style="background-color: #000"
                ></label>
              </div>
              <div class="color-selector">
                <input type="radio" name="color" id="white" class="hidden" />
                <label
                  for="white"
                  class="border border-gray-200 rounded-sm h-6 w-6 cursor-pointer shadow-sm block"
                  style="background-color: #fff"
                ></label>
              </div>
            </div>
          </div> -->
        </div>
      </div>
  
      <!-- ./sidebar -->
      <div
        class="col-span-1 bg-white px-4 pb-6 shadow rounded overflow-hiddenb hidden md:block"
      >
        <div class="divide-y divide-gray-200 space-y-5">
          <div v-if="!$route.query.collectionId && !$route.query.categoryId">
            <h3 class="text-xl text-gray-800 mb-3 uppercase font-medium">
              Categorias
            </h3>
            <div class="space-y-2">
              <div class="flex items-center" v-for="category in categories">
                <input
                  type="radio"
                  name="category-1"
                  :id="`cat-${category.id}`"
                  class="text-primary focus:ring-0 rounded-full cursor-pointer"
                  v-model="filter.categoryId"
                  :value="category.id"
                />
                <label :for="`cat-${category.id}`" class="text-gray-600 ml-3 cursor-pointer"
                  >{{ category.title }}</label
                >
                <div class="ml-auto text-gray-600 text-sm">({{ category.products?.length ?? 0 }})</div>
              </div>
            </div>
          </div>
  
          <div class="pt-4" v-if="!$route.query.collectionId && !$route.query.categoryId">
            <h3 class="text-xl text-gray-800 mb-3 uppercase font-medium">
              Coleções
            </h3>
            <div class="space-y-2">
              <div class="flex items-center" v-for="collection in collections">
                <input
                  type="radio"
                  name="brand-1"
                  :id="`collection-${collection.id}`"
                  :value="collection.id"
                  class="text-primary focus:ring-0 rounded-full cursor-pointer"
                  v-model="filter.collectionId"
                />
                <label :for="`collection-${collection.id}`" class="text-gray-600 ml-3 cursor-pointer"
                  >{{ collection.title }}</label
                >
                <!-- <div class="ml-auto text-gray-600 text-sm">(15)</div> -->
              </div>
            </div>
          </div>
  
          <div class="pt-4">
            <h3 class="text-xl text-gray-800 mb-3 uppercase font-medium">
              Preço
            </h3>
            <div class="mt-4 flex items-center">
              <input
                type="number"
                name="min"
                id="min"
                class="w-full border-gray-300 focus:border-primary rounded focus:ring-0 px-3 py-1 text-gray-600 shadow-sm"
                placeholder="min"
                v-model="filter.minPrice"
              />
              <span class="mx-3 text-gray-500">-</span>
              <input
                type="number"
                name="max"
                id="max"
                class="w-full border-gray-300 focus:border-primary rounded focus:ring-0 px-3 py-1 text-gray-600 shadow-sm"
                placeholder="max"
                v-model="filter.maxPrice"
              />
            </div>
          </div>
  
          <!-- <div class="pt-4">
            <h3 class="text-xl text-gray-800 mb-3 uppercase font-medium">tamanho</h3>
            <div class="flex items-center gap-2">
              <div class="size-selector">
                <input type="radio" name="size" id="size-xs" class="hidden" />
                <label
                  for="size-xs"
                  class="text-xs border border-gray-200 rounded-sm h-6 w-6 flex items-center justify-center cursor-pointer shadow-sm text-gray-600"
                  >XS</label
                >
              </div>
              <div class="size-selector">
                <input type="radio" name="size" id="size-sm" class="hidden" />
                <label
                  for="size-sm"
                  class="text-xs border border-gray-200 rounded-sm h-6 w-6 flex items-center justify-center cursor-pointer shadow-sm text-gray-600"
                  >S</label
                >
              </div>
              <div class="size-selector">
                <input type="radio" name="size" id="size-m" class="hidden" />
                <label
                  for="size-m"
                  class="text-xs border border-gray-200 rounded-sm h-6 w-6 flex items-center justify-center cursor-pointer shadow-sm text-gray-600"
                  >M</label
                >
              </div>
              <div class="size-selector">
                <input type="radio" name="size" id="size-l" class="hidden" />
                <label
                  for="size-l"
                  class="text-xs border border-gray-200 rounded-sm h-6 w-6 flex items-center justify-center cursor-pointer shadow-sm text-gray-600"
                  >L</label
                >
              </div>
              <div class="size-selector">
                <input type="radio" name="size" id="size-xl" class="hidden" />
                <label
                  for="size-xl"
                  class="text-xs border border-gray-200 rounded-sm h-6 w-6 flex items-center justify-center cursor-pointer shadow-sm text-gray-600"
                  >XL</label
                >
              </div>
            </div>
          </div>
  
          <div class="pt-4">
            <h3 class="text-xl text-gray-800 mb-3 uppercase font-medium">
              Cor
            </h3>
            <div class="flex items-center gap-2">
              <div class="color-selector">
                <input type="radio" name="color" id="red" class="hidden" />
                <label
                  for="red"
                  class="border border-gray-200 rounded-sm h-6 w-6 cursor-pointer shadow-sm block"
                  style="background-color: #fc3d57"
                ></label>
              </div>
              <div class="color-selector">
                <input type="radio" name="color" id="black" class="hidden" />
                <label
                  for="black"
                  class="border border-gray-200 rounded-sm h-6 w-6 cursor-pointer shadow-sm block"
                  style="background-color: #000"
                ></label>
              </div>
              <div class="color-selector">
                <input type="radio" name="color" id="white" class="hidden" />
                <label
                  for="white"
                  class="border border-gray-200 rounded-sm h-6 w-6 cursor-pointer shadow-sm block"
                  style="background-color: #fff"
                ></label>
              </div>
            </div>
          </div> -->

          <div class="pt-4 flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"  class="stroke-zinc-400"/>
              <path d="M18.8999 5L4.8999 19" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="stroke-zinc-500"/>
            </svg>
            <button class="text-zinc-500" @click="cleanFilter">Limpar filtros</button>
          </div>
        </div>
      </div>
      <!-- products -->
      <div class="col-span-3">
        <div class="flex items-center mb-4">
          <select
            name="sort"
            id="sort"
            class="w-64 text-sm text-gray-600 py-3 px-4 border-gray-300 shadow-sm rounded focus:ring-primary focus:border-primary"
            v-model="filter.orderBy"
          >
            <option value="">Ordem padrão</option>
            <option value="priceAsc">Preço baixo a preço alto</option>
            <option value="priceDesc">Preço alto a preço baixo</option>
            <option value="latest">Produtos mais recentes</option>
          </select>
  
          <!-- <div class="flex gap-2 ml-auto">
            <div
              class="border border-primary w-10 h-9 flex items-center justify-center text-white bg-primary rounded cursor-pointer"
            >
              <i class="fa-solid fa-grip-vertical"></i>
            </div>
            <div
              class="border border-gray-300 w-10 h-9 flex items-center justify-center text-gray-600 rounded cursor-pointer"
            >
              <i class="fa-solid fa-list"></i>
            </div>
          </div> -->
        </div>
  
        <div class="grid lg:grid-cols-3 sm:grid-cols-2 gap-6">
          <div v-if="!visibleProducts.length" class="flex flex-col justify-center items-center col-span-12 min-h-80">
            <svg width="202" height="116" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none"><path d="M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z" fill="#676767"></path></svg>
            <p class="font-medium pt-4">Nenhum produto foi encontrado</p>
          </div>
          <RouterLink :to="{ name: 'product', params: { slug: generateProductSlug(product.title) } }" class="bg-white shadow rounded overflow-hidden group" v-for="product in visibleProducts">
            <div class="relative">
              <img
                :src="product.images?.length ? product.images[0].source : '/assets/images/no-image.png'"
                alt="product 1"
                class="w-full object-contain"
                style="height: 20rem"
              />
              <div
                class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition"
              >
                <!-- <a
                  href="#"
                  class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                  title="view product"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </a>
                <a
                  href="#"
                  class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
                  title="add to wishlist"
                >
                  <i class="fa-solid fa-heart"></i>
                </a> -->
              </div>
            </div>
            <div class="pt-4 pb-3 px-4">
              <a href="#">
                <h4
                  class="uppercase font-medium text-xl mb-2 text-gray-800 hover:text-primary transition"
                >
                  {{ product.title }}
                </h4>
              </a>
              <div class="flex items-baseline mb-1 space-x-2">
                <template v-if="product.variants[0].promotionalPrice">
                  <p class="text-xl text-primary font-semibold">{{ product.variants[0].promotionalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                  <p class="text-sm text-gray-400 line-through">{{ product.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                </template>
                <template v-else>
                  <p class="text-xl text-primary font-semibold">{{ product.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                </template>
              </div>
              <!-- <div class="flex items-center">
                <div class="flex gap-1 text-sm text-yellow-400">
                  <span><i class="fa-solid fa-star"></i></span>
                  <span><i class="fa-solid fa-star"></i></span>
                  <span><i class="fa-solid fa-star"></i></span>
                  <span><i class="fa-solid fa-star"></i></span>
                  <span><i class="fa-solid fa-star"></i></span>
                </div>
                <div class="text-xs text-gray-500 ml-3">(150)</div>
              </div> -->
            </div>
            <a
              href="#"
              class="block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition"
              >Adicionar</a
            >
          </RouterLink>
        </div>
      </div>
  
      <!-- ./products -->
    </div>
    <!-- ./shop wrapper -->
  </PinkThemeLayout>
</template>

<script setup lang="ts">
import PinkThemeLayout from "../layouts/PinkThemeLayout.vue";
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useProduct } from "@/composables/useProduct";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { generateProductSlug } from '@/interfaces/products/Product';

const route = useRoute();
const { products } = useProduct()
const { categories } = useCategory()
const { collections } = useCollection()

const filter = ref({ 
  categoryId: null, 
  collectionId: null, 
  orderBy: '', 
  minPrice: null,
  maxPrice: null, 
})
const collection = computed(() => collections.value.find((c) => c.id === Number(route.query.collectionId)))
const isMobileSidebarOpen = ref(false)
const visibleProducts = computed(() => {
  const selectedCollection = filter.value.collectionId
    ? collections.value.find((c) => c.id === filter.value.collectionId)
    : null;

  const filteredProducts = products.value?.filter((p) => {
    const hasCategory = p.categoryId === filter.value.categoryId || !filter.value.categoryId
    const matchesSearch = route.query.search ? p.title.toLowerCase().includes(String(route.query.search).toLowerCase()) : true
    
    const withinPriceRange = (
      (!filter.value.minPrice || (p.variants[0].promotionalPrice ?? p.variants[0].price)! >= filter.value.minPrice) &&
      (!filter.value.maxPrice || (p.variants[0].promotionalPrice ?? p.variants[0].price)! <= filter.value.maxPrice)
    )

    const inSelectedCollection = selectedCollection
      ? selectedCollection.productIds.includes(p.id)
      : true;

    if (!route.query.categoryId && !route.query.collectionId) {
      return matchesSearch && hasCategory && withinPriceRange && inSelectedCollection
    } else {
      return (p.categoryId === Number(route.query.categoryId) || collection.value?.productIds.includes(p.id)) && withinPriceRange
    }
  }) ?? []

  if (filter.value.orderBy === 'priceAsc') {
    return filteredProducts.sort((a, b) => a.variants[0].price! - b.variants[0].price!)
  } else if (filter.value.orderBy === 'priceDesc') {
    return filteredProducts.sort((a, b) => b.variants[0].price! - a.variants[0].price!)
  } else if (filter.value.orderBy === 'latest') {
    return filteredProducts.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  }

  return filteredProducts
})

function cleanFilter() {
  filter.value = { 
    categoryId: null, 
    collectionId: null, 
    orderBy: '', 
    minPrice: null,
    maxPrice: null, 
  }
}
</script>
