import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white pt-16 pb-12 border-t border-gray-100" }
const _hoisted_2 = { class: "container grid grid-cols-1" }
const _hoisted_3 = { class: "col-span-1 space-y-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mr-2" }
const _hoisted_6 = { class: "text-gray-500 text-sm" }
const _hoisted_7 = { class: "text-gray-500 text-sm" }
const _hoisted_8 = { class: "bg-gray-800 py-4 bottom-footer" }
const _hoisted_9 = { class: "container flex items-center justify-between" }
const _hoisted_10 = { class: "text-white" }

import { useEcommerce } from '@/composables/useEcommerce';
import { useFormat } from '@/composables/useFormat';


export default /*@__PURE__*/_defineComponent({
  __name: 'PinkThemeFooter',
  setup(__props) {

const { formatDocument } = useFormat()
const { ecommerce } = useEcommerce()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("footer", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _unref(ecommerce)?.visual?.urlLogo,
            alt: "logo",
            class: "w-30"
          }, null, 8, _hoisted_4),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(ecommerce).email), 1),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(ecommerce).address?.street) + ", " + _toDisplayString(_unref(ecommerce).address?.district) + ", Número " + _toDisplayString(_unref(ecommerce).address?.number) + ", " + _toDisplayString(_unref(ecommerce).address?.complement), 1)
          ]),
          _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"flex space-x-5\"><a href=\"#\" class=\"text-gray-400 hover:text-gray-500\"><i class=\"fa-brands fa-facebook-square\"></i></a><a href=\"#\" class=\"text-gray-400 hover:text-gray-500\"><i class=\"fa-brands fa-instagram-square\"></i></a><a href=\"#\" class=\"text-gray-400 hover:text-gray-500\"><i class=\"fa-brands fa-twitter-square\"></i></a><a href=\"#\" class=\"text-gray-400 hover:text-gray-500\"><i class=\"fa-brands fa-github-square\"></i></a></div>", 1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", _hoisted_10, "Copyright © " + _toDisplayString(_unref(ecommerce).title) + " - " + _toDisplayString(_unref(formatDocument)(_unref(ecommerce).document)) + " - " + _toDisplayString(new Date().getFullYear()) + ". Todos os direitos reservados.", 1),
        _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"flex gap-2 items-center flex-wrap\"><img src=\"assets/images/payment-methods/pix.png\" alt=\"methods\" class=\"h-5\"><img src=\"assets/images/payment-methods/boleto.jpeg\" alt=\"methods\" class=\"h-5\"><img src=\"assets/images/payment-methods/bandeiras/elo.png\" alt=\"methods\" class=\"h-5\"><img src=\"assets/images/payment-methods/bandeiras/hipercard.svg\" alt=\"methods\" class=\"h-5\"><img src=\"assets/images/payment-methods/bandeiras/master.svg\" alt=\"methods\" class=\"h-5\"><img src=\"assets/images/payment-methods/bandeiras/visa.svg\" alt=\"methods\" class=\"h-5\"></div>", 1))
      ])
    ])
  ], 64))
}
}

})