<template>
  <div class="simple-spinner">
    <span class="loader"></span>
  </div>
</template>

<style lang="scss">
  .simple-spinner {
    width: 50px;
    height: 50px;
  }

  .simple-spinner .loader {
    width: 48px;
    height: 48px;
    border: 5px solid pink;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 0.8s linear infinite;
  }

  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
</style>
