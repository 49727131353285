import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "bg-gray-800" }
const _hoisted_2 = { class: "container flex" }
const _hoisted_3 = { class: "px-4 sm:px-6 sm:py-4 bg-primary flex items-center cursor-pointer relative group" }
const _hoisted_4 = { class: "absolute left-0 top-full bg-white shadow-md py-3 divide-y divide-gray-300 divide-dashed opacity-0 group-hover:opacity-100 transition duration-300 invisible group-hover:visible w-64" }
const _hoisted_5 = { class: "ml-6 text-gray-600 text-sm" }
const _hoisted_6 = { class: "ml-6 text-gray-600 text-sm" }
const _hoisted_7 = { class: "flex items-center justify-between flex-grow pl-8 md:pl-12 py-5" }
const _hoisted_8 = { class: "flex items-center space-x-6 capitalize" }
const _hoisted_9 = ["href"]

import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useEcommerce } from '@/composables/useEcommerce';


export default /*@__PURE__*/_defineComponent({
  __name: 'PinkThemeNav',
  setup(__props) {

_useCssVars(_ctx => ({
  "3702f79c": (_unref(ecommerce)?.visual?.colorMenuText ?? 'rgb(253 61 87 / var(--tw-bg-opacity))'),
  "3ec69099": (_unref(ecommerce)?.visual?.colorMenuHover ?? 'rgb(253 61 87 / var(--tw-bg-opacity))')
}))

const { categories } = useCategory()
const { collections } = useCollection()
const { ecommerce } = useEcommerce()

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createStaticVNode("<span class=\"text-white\" data-v-1165b3e6><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"28\" height=\"28\" viewBox=\"0 0 24 24\" fill=\"none\" data-v-1165b3e6><path d=\"M3 7H21\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" data-v-1165b3e6></path><path d=\"M3 12H21\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" data-v-1165b3e6></path><path d=\"M3 17H21\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" data-v-1165b3e6></path></svg></span><span class=\"capitalize ml-2 text-white hidden\" data-v-1165b3e6>Todas categorias</span>", 2)),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category) => {
            return (_openBlock(), _createBlock(_component_RouterLink, {
              to: { name: 'shop', query: { categoryId: category.id } },
              class: "flex items-center px-6 py-3 hover:bg-gray-100 transition"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(category.title), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 256)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(collections), (collection) => {
            return (_openBlock(), _createBlock(_component_RouterLink, {
              to: { name: 'shop', query: { collectionId: collection.id } },
              class: "flex items-center px-6 py-3 hover:bg-gray-100 transition"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_6, _toDisplayString(collection.title), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 256))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_RouterLink, {
            to: { name: 'home' },
            class: "text-menu hover:text-menu-hover transition",
            "active-class": 'text-menu-hover'
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Home")
            ])),
            _: 1
          }),
          _createVNode(_component_RouterLink, {
            to: { name: 'shop' },
            class: "text-menu hover:text-menu-hover transition",
            "active-class": 'text-menu-hover'
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Produtos")
            ])),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ecommerce)?.menus, (menu) => {
            return (_openBlock(), _createElementBlock("a", {
              href: menu.href,
              key: menu.id
            }, _toDisplayString(menu.title), 9, _hoisted_9))
          }), 128))
        ])
      ])
    ])
  ]))
}
}

})