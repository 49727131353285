import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "text-6xl text-gray-800 font-medium mb-4 capitalize" }
const _hoisted_3 = { class: "mt-12" }
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 0,
  class: "container pb-16 pt-16"
}
const _hoisted_6 = { class: "text-2xl font-medium text-gray-800 uppercase mb-6" }
const _hoisted_7 = { class: "grid grid-cols-1 md:grid-cols-4 gap-6" }
const _hoisted_8 = { class: "relative" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "pt-4 pb-3 px-4" }
const _hoisted_11 = { href: "#" }
const _hoisted_12 = { class: "uppercase font-medium text-xl mb-2 text-gray-800 hover:text-primary transition" }
const _hoisted_13 = { class: "flex items-baseline mb-1 space-x-2" }
const _hoisted_14 = { class: "text-xl text-primary font-semibold" }
const _hoisted_15 = { class: "text-sm text-gray-400 line-through" }
const _hoisted_16 = {
  key: 1,
  class: "text-xl text-primary font-semibold"
}
const _hoisted_17 = { class: "container pb-16 flex flex-col gap-8" }
const _hoisted_18 = ["href"]
const _hoisted_19 = ["src"]

import PinkThemeLayout from './layouts/PinkThemeLayout.vue';
import { useCollection } from '@/composables/useCollection';
import { useCategory } from '@/composables/useCategory';
import { generateProductSlug } from '@/interfaces/products/Product';
import { useEcommerce } from '@/composables/useEcommerce';


export default /*@__PURE__*/_defineComponent({
  __name: 'PinkThemeHomeView',
  setup(__props) {

const { ecommerce, topBanner, bottomBanners } = useEcommerce()
const { categories } = useCategory()
const { collections } = useCollection()

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(PinkThemeLayout, null, {
    default: _withCtx(() => [
      (_unref(topBanner))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "bg-cover bg-no-repeat bg-center py-36",
            style: _normalizeStyle({ backgroundImage: `url('${_unref(topBanner).url}')` })
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("h1", _hoisted_2, _toDisplayString(_unref(topBanner).name), 1),
              _createElementVNode("p", null, _toDisplayString(_unref(topBanner).description), 1),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("a", {
                  href: _unref(topBanner).redirectUrl,
                  class: "bg-primary border border-primary text-white px-8 py-3 font-medium rounded-md hover:bg-transparent hover:text-primary"
                }, _toDisplayString(_unref(topBanner).buttonText), 9, _hoisted_4)
              ])
            ])
          ], 4))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: category.id
        }, [
          (category.products?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("h2", _hoisted_6, _toDisplayString(category.title), 1),
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.products, (product) => {
                    return (_openBlock(), _createBlock(_component_RouterLink, {
                      to: { name: 'product', params: { slug: _unref(generateProductSlug)(product.title) } },
                      class: "bg-white shadow rounded overflow-hidden group"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("img", {
                            src: product.images?.length ? product.images[0].source : '/assets/images/no-image.png',
                            alt: "product 1",
                            class: "w-full object-contain",
                            style: {"height":"20rem"},
                            onError: _cache[0] || (_cache[0] = ($event: any) => (($event.target as HTMLImageElement).src = '/assets/images/no-image.png'))
                          }, null, 40, _hoisted_9),
                          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("a", _hoisted_11, [
                            _createElementVNode("h4", _hoisted_12, _toDisplayString(product.title), 1)
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            (product.variants[0].promotionalPrice)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createElementVNode("p", _hoisted_14, _toDisplayString(product.variants[0].promotionalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1),
                                  _createElementVNode("p", _hoisted_15, _toDisplayString(product.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1)
                                ], 64))
                              : (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(product.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1))
                          ])
                        ]),
                        _cache[2] || (_cache[2] = _createElementVNode("a", {
                          href: "#",
                          class: "block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition"
                        }, "Adicionar", -1))
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 256))
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      _createElementVNode("div", _hoisted_17, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(bottomBanners), (banner) => {
          return (_openBlock(), _createElementBlock("a", {
            href: banner.redirectUrl
          }, [
            _createElementVNode("img", {
              src: banner.url,
              alt: "ads",
              class: "w-full"
            }, null, 8, _hoisted_19)
          ], 8, _hoisted_18))
        }), 256))
      ])
    ]),
    _: 1
  }))
}
}

})