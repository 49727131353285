import axiosClient from "@/config/axios";


export function useCache() {
  async function needUpdate(): Promise<number> {
    const { data } = await axiosClient.get("/cache/need-update")

    return data.lastUpdate
  }

  return {
    needUpdate
  }
}