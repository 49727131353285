<template>
<!-- header -->
<header class="py-4 shadow-sm bg-white">
  <div class="container flex items-center justify-between">
    <RouterLink :to="{ name: 'home' }">
      <img :src="ecommerce?.visual?.urlLogo" alt="Logo" class="w-32" />
    </RouterLink>

    <div class="w-full max-w-xl relative flex">
      <span class="absolute left-4 top-1/2 -translate-y-1/2 text-lg text-gray-400">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="search-icon hidden sm:block"><path d="M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z" fill="#676767"></path></svg>
      </span>
      <input
        type="text"
        name="search"
        id="search"
        class="w-full border border-primary border-r-0 pl-12 py-3 pr-3 rounded-l-md focus:outline-none hidden md:flex"
        placeholder="Pesquisar por produto"
        @keydown.enter="$router.push({ name: 'shop', query: { search: search } })"
        v-model="search"
      />
      <button
        class="bg-primary border border-primary text-white px-8 rounded-r-md hover:bg-transparent hover:text-primary transition hidden md:flex items-center"
        @click="$router.push({ name: 'shop', query: { search: search } })"
      >
        Pesquisar
      </button>
    </div>

    <div class="flex items-center space-x-4">
      <RouterLink
        :to="{ name: 'cart' }"
        class="text-center text-gray-700 hover:text-primary transition relative link duration-200 transition-color"
        :active-class="'text-primary'"
      >
        <div class="text-2xl flex justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M19.9602 8.96002C19.2902 8.22002 18.2802 7.79002 16.8802 7.64002V6.88002C16.8802 5.51002 16.3002 4.19002 15.2802 3.27002C14.2502 2.33002 12.9102 1.89002 11.5202 2.02002C9.13023 2.25002 7.12023 4.56002 7.12023 7.06002V7.64002C5.72023 7.79002 4.71023 8.22002 4.04023 8.96002C3.07023 10.04 3.10023 11.48 3.21023 12.48L3.91023 18.05C4.12023 20 4.91023 22 9.21023 22H14.7902C19.0902 22 19.8802 20 20.0902 18.06L20.7902 12.47C20.9002 11.48 20.9202 10.04 19.9602 8.96002ZM11.6602 3.41002C12.6602 3.32002 13.6102 3.63002 14.3502 4.30002C15.0802 4.96002 15.4902 5.90002 15.4902 6.88002V7.58002H8.51023V7.06002C8.51023 5.28002 9.98023 3.57002 11.6602 3.41002ZM8.42023 13.15H8.41023C7.86023 13.15 7.41023 12.7 7.41023 12.15C7.41023 11.6 7.86023 11.15 8.41023 11.15C8.97023 11.15 9.42023 11.6 9.42023 12.15C9.42023 12.7 8.97023 13.15 8.42023 13.15ZM15.4202 13.15H15.4102C14.8602 13.15 14.4102 12.7 14.4102 12.15C14.4102 11.6 14.8602 11.15 15.4102 11.15C15.9702 11.15 16.4202 11.6 16.4202 12.15C16.4202 12.7 15.9702 13.15 15.4202 13.15Z" fill="#171717" class="duration-200 transition-fill" />
          </svg>
        </div>
        <div class="text-xs leading-3">Sacola</div>
        <div
          v-if="cart.length"
          class="absolute -right-3 -top-1 w-5 h-5 rounded-full flex items-center justify-center bg-primary text-white text-xs"
        >
          {{ cart.length }}
        </div>
      </RouterLink>
    </div>
  </div>
</header>
<!-- ./header -->
</template>

<script setup lang="ts">
import { useCart } from '@/composables/useCart';
import { useEcommerce } from '@/composables/useEcommerce';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute()
const { ecommerce } = useEcommerce()
const { cart } = useCart()
const search = ref(route.query.search ?? '')
</script>

<style lang="scss" scoped>
$primary-color: v-bind("ecommerce?.visual?.colorPrimary ?? 'rgb(253 61 87 / var(--tw-bg-opacity))'");

.text-primary, .link:hover {
  color: $primary-color;
  path {
    fill: $primary-color
  }
}
</style>