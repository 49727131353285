<template>
  <div class="grid text-center items-center justify-center h-[100vh]">
    <div class="grid gap-10">
      <h1 class="text-6xl font-extrabold">404</h1>
      <h2 class="text-3xl font-bold">Loja não encontrada</h2>

      <svg class="mx-auto h-40 w-40 text-gray-600" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path opacity="0.4" d="M21.3699 11.39V17.38C21.3699 20.14 19.1299 22.38 16.3699 22.38H7.62988C4.86988 22.38 2.62988 20.14 2.62988 17.38V11.46C3.38988 12.28 4.46988 12.75 5.63988 12.75C6.89988 12.75 8.10988 12.12 8.86988 11.11C9.54988 12.12 10.7099 12.75 11.9999 12.75C13.2799 12.75 14.4199 12.15 15.1099 11.15C15.8799 12.14 17.0699 12.75 18.3099 12.75C19.5199 12.75 20.6199 12.26 21.3699 11.39Z" fill="#171717"/>
        <path d="M14.9899 1.25H8.98985L8.24985 8.61C8.18985 9.29 8.28985 9.93 8.53985 10.51C9.11985 11.87 10.4799 12.75 11.9999 12.75C13.5399 12.75 14.8699 11.89 15.4699 10.52C15.6499 10.09 15.7599 9.59 15.7699 9.08V8.89L14.9899 1.25Z" fill="#171717"/>
        <path opacity="0.6" d="M22.3598 8.27L22.0698 5.5C21.6498 2.48 20.2798 1.25 17.3498 1.25H13.5098L14.2498 8.75C14.2598 8.85 14.2698 8.96 14.2698 9.15C14.3298 9.67 14.4898 10.15 14.7298 10.58C15.4498 11.9 16.8498 12.75 18.3098 12.75C19.6398 12.75 20.8398 12.16 21.5898 11.12C22.1898 10.32 22.4598 9.31 22.3598 8.27Z" fill="#171717"/>
        <path opacity="0.6" d="M6.59014 1.25C3.65014 1.25 2.29014 2.48 1.86014 5.53L1.59014 8.28C1.49014 9.35 1.78014 10.39 2.41014 11.2C3.17014 12.19 4.34014 12.75 5.64014 12.75C7.10014 12.75 8.50014 11.9 9.21014 10.6C9.47014 10.15 9.64014 9.63 9.69014 9.09L10.4701 1.26H6.59014V1.25Z" fill="#171717"/>
        <path d="M11.3501 16.66C10.0801 16.79 9.12012 17.87 9.12012 19.15V22.38H14.8701V19.5C14.8801 17.41 13.6501 16.42 11.3501 16.66Z" fill="#171717"/>
      </svg>

      <p class="text-lg">
        Oops! Não conseguimos encontrar a loja que você está procurando. <br>
        Ela pode ter sido removida ou o endereço está incorreto.
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>