export function useFormat() {
  function formatDocument(value?: string) {
    if (!value) {
      return 'Sem documento'
    }
  
    const cnpjCpf = value.replace(/\D/g, '');
    
    if (cnpjCpf.length === 11) {
      return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
    } 
    
    return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
  }

  return {
    formatDocument
  }
}