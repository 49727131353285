import axiosClient from "@/config/axios";
import { Ecommerce } from "@/interfaces/ecommerce/Ecommerce";
import { ref } from "vue";
import { computed } from 'vue';

const ecommerce = ref<Ecommerce | Partial<Ecommerce>>(JSON.parse(localStorage.getItem('ecommerce') ?? '{}'))
const isEcommerceLoading = ref(false)

const topBanner = computed(() => {
  return ecommerce.value?.banners?.reduce((minBanner, currentBanner) => {
    if (minBanner?.order !== undefined && currentBanner?.order !== undefined) {
      return minBanner.order < currentBanner.order ? minBanner : currentBanner;
    }
    return minBanner;
  });
});
const bottomBanners = computed(() => ecommerce.value?.banners?.filter((banner) => banner.id !== topBanner.value?.id))

function addFavicon() {
  const existingFavicons = document.querySelectorAll('link[rel="icon"]');
  existingFavicons.forEach(favicon => favicon.remove());

  const link = document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'icon';
  link.href = ecommerce.value.visual?.urlFavicon!;
  document.head.appendChild(link);
}

function addPageTitle() {
  document.querySelector('title')?.remove()

  const titleEl = document.createElement('title')
  titleEl.innerText = ecommerce.value?.title ?? ''
  document.head.append(titleEl)
}

export function useEcommerce() {
  function loadEcommerce(refresh = false) {
    if (!ecommerce.value.id || refresh) {
      isEcommerceLoading.value = true 

      axiosClient.get('/details')
        .then((res) => {
          ecommerce.value = res.data
          localStorage.setItem('ecommerce', JSON.stringify(res.data))
        })
        .finally(() => isEcommerceLoading.value = false)
    }

    addFavicon()
    addPageTitle()
  }

  return {
    ecommerce,
    loadEcommerce,
    isEcommerceLoading,
    topBanner,
    bottomBanners
  }
}